import Home, {
  getServerSideProps as hgetServerSideProps,
} from '@pages/myapp2/index';
import { GetServerSideProps } from 'next';

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const ret = await hgetServerSideProps(ctx);
  return ret;
};

export default Home;
